/* Component Dependencies */
var resLinkEventInfoTemplate = require('templates/resLinkEventInfo.hbs');
var AriesComponent = require('libs/aries-component');
var $ = require('jquery');
require('libs/jquery.dotdotdot');

AriesComponent.create({
  
  type: 'resLinkEventInfo',
  template: {
    'resLinkEventInfo': resLinkEventInfoTemplate
  },
  bindEvents: function() {
    this.$el.find('.t-event-details h2').dotdotdot();
  },
});